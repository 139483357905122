import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Link } from 'gatsby'

import { Layout, Button } from 'components'

import { media } from 'resources'

const Login = () => {
  return (
    <Layout>
      <Wrapper>
        <Content>
          <Header>
            <Logo to='/'>
              <img src='/logo.svg' alt='Professor Ferretto' />
            </Logo>
          </Header>
          <PlatformSelect>
            <Platform>
              <Image>
                <img src='/plataforma-nova.jpg' width='400' height='200' alt='Plataforma nova' />
              </Image>
              <Metadata>
                <Title>
                  Plataforma nova
                </Title>
                <Description>
                  Se você já era aluno da plataforma antiga e está realizando seu primeiro acesso, você precisará criar uma nova senha <a href={`${process.env.GATSBY_WEB_URL}/recuperar-senha`}>clicando aqui</a>.
                </Description>
                <ButtonArea>
                  <Button
                    href={process.env.GATSBY_WEB_URL}
                    size='large'
                    variant='default'
                    shadow
                  >
                    Acessar
                  </Button>
                </ButtonArea>
              </Metadata>
            </Platform>
            <Platform>
              <Image>
                <img src='/plataforma-antiga.jpg' width='400' height='200' alt='Plataforma antiga' />
              </Image>
              <Metadata>
                <Title>
                  Plataforma antiga
                </Title>
                <Description>
                  Para alunos que possuiam conta no Professor Ferretto até dia 06/01/2021.
                </Description>
                <ButtonArea>
                  <Button
                    href='https://plataforma.professorferretto.com.br'
                    size='large'
                    variant='default'
                    shadow
                  >
                    Acessar
                  </Button>
                </ButtonArea>
              </Metadata>
            </Platform>
          </PlatformSelect>
        </Content>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.main`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan('sm')`
    height: auto;
    margin-top: 5rem;
  `}
`

const Content = styled.section`
  width: 100%;
  height: auto;
  margin-top: -2rem;

  ${media.lessThan('sm')`
    margin-top: 0;
  `}
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;
`

const Logo = styled(props => <Link {...props} />)`
  display: block;
  max-width: 22rem;
  text-decoration: none;

  img {
    max-width: 100%;
  }
`

const PlatformSelect = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan('sm')`
    display: block;
  `}
`

const Platform = styled.div`
  width: 100%;
  padding: 2.6rem 1.6rem;
  text-align: center;
  margin: 0 2rem;
  border-radius: ${({ theme }) => theme.radius.md};
  transition: background .5s ease;

  ${media.greaterThan('sm')`
    &:hover {
      background: ${({ theme }) => rgba(theme.colors.secondary, 0.7)};
    }
  `}

  &:last-child {
    order: -1;

    ${media.lessThan('sm')`
      margin: 5rem 0;
    `}
  }

  ${media.lessThan('sm')`
    margin: 0;
  `}
`

const Image = styled.div`
  margin-bottom: .8rem;
  display: inline-block;

  img {
    max-width: 100%;
    border-radius: ${({ theme }) => theme.radius.md};
    background: ${({ theme }) => rgba(theme.colors.text, 0.04)};
    min-height: 200px;
    height: 200px;
    border: 1px solid ${({ theme }) => theme.colors.secondary};

    ${media.lessThan('md')`
      min-height: 0;
      height: auto;
    `}
  }
`

const Metadata = styled.div`
`

const Title = styled.h2`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.primary};
  line-height: 2;
  padding: .2em 0;
`

const Description = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.text};
  padding: 0;
  margin: 0 auto;
  line-height: 1.6;
  max-width: 90%;
  min-height: 100px;
  text-align: center;

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.primary, 0.3)};
    transition: border-bottom .3s ease;

    &:hover {
      border-bottom-color: ${({ theme }) => rgba(theme.colors.primary, 0.8)};
    }
  }

  ${media.lessThan('md')`
    min-height: 0;
  `}
`

const ButtonArea = styled.div`
  ${media.lessThan('md')`
    padding-top: 2rem;
  `}
`

export default Login
